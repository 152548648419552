

















import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class InputLabel extends Vue {
  @Prop({ default: false }) conditional!: boolean;
  @Prop() required!: boolean;
  @Prop() title!: string;
  @Prop() error?: boolean;
  @Prop() errorTooltipText?: string;

  private get normalizedErrorText(): string {
    return this.errorTooltipText && this.errorTooltipText?.length > 0
      ? this.errorTooltipText
      : `Error: The ${this.title?.toLowerCase()} specified is not valid`;
  }
}
