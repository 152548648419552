import { ResultColumns } from "@/interfaces/advanced-search/advanced-search-interfaces";

export enum SelectedDisplayOption {
  removeSelected = 1,
  showOnlySelected = 2
}

export type CategoryAndFieldsObject = {
  category: string;
  fields: ResultColumns[];
};

export type AdvancedSearchOptionCategories = {
  categories: CategoryAndFieldsObject[];
};

export interface FieldsGenerator {
  clearAllData(): void;
  resetValues(): void;
}

export interface GeneratedField {
  clearValue(): void;
}
