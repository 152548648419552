import { render, staticRenderFns } from "./InputLabel.vue?vue&type=template&id=434dccd2&scoped=true&"
import script from "./InputLabel.vue?vue&type=script&lang=ts&"
export * from "./InputLabel.vue?vue&type=script&lang=ts&"
import style0 from "./InputLabel.vue?vue&type=style&index=0&id=434dccd2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "434dccd2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VIcon,VTooltip})
