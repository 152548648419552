import moment from "moment";
import { app } from "@/main";
import { SavedSearch } from "@/interfaces/advanced-search/saved-search-interfaces";
import {
  AdvancedSearchRequest,
  AdvancedSearchItem,
  SaveAdvancedSearchRequest,
  ResultColumns
} from "@/interfaces/advanced-search/advanced-search-interfaces";
import { RavenApiStandardResponse } from "@/models/external-services/raven-api-standard-response";
import {
  AdvancedSearchOptionCategories,
  CategoryAndFieldsObject
} from "@/models/advanced-search/invoice-search-options";

class AdvancedSearchService {
  public async getAdvancedSearchOptions(): Promise<
    CategoryAndFieldsObject[] | any
  > {
    const options: AdvancedSearchOptionCategories = await app.$ravenapi.get(
      "api/advancedsearch/options"
    );

    if (options?.categories) {
      return options?.categories?.map((category: CategoryAndFieldsObject) => {
        return {
          category: category?.category,
          fields: category?.fields?.map((field: ResultColumns) => {
            return {
              ...field,
              options: JSON.parse(field?.options as string)
            };
          })
        };
      });
    }
    return options;
  }

  public async getSavedSearches(): Promise<SavedSearch[]> {
    const apiResponse: any = await app.$ravenapi.get(
      "api/advancedsearch/searches"
    );

    return apiResponse;
  }

  public async getAdvancedSearchResults(
    params: AdvancedSearchRequest
  ): Promise<AdvancedSearchItem[]> {
    params.valuesToSearch.forEach(value => {
      const resultColumn = params.resultColumns.filter(
        result => result.idx == value.idx
      );
      if (resultColumn[0]?.type == "dtm") {
        value.val = moment(value.val)
          .utc()
          .format();
      }
    });

    const apiResponse: any = await app.$ravenapi.post(
      "api/advancedsearch/searches",
      params
    );

    return apiResponse;
  }

  public async deleteSavedSearch(savedSearchId): Promise<any> {
    const apiResponse = await app.$ravenapi.delete(
      `api/advancedsearch/searches/${savedSearchId}`
    );
    return apiResponse.data;
  }

  public async loadExistingSearchAsync(
    savedSearchId: number | string
  ): Promise<SavedSearch | RavenApiStandardResponse> {
    const apiResponse: any = await app.$ravenapi.get(
      `api/advancedsearch/searches/${savedSearchId}`
    );

    return apiResponse;
  }

  public async saveNewSearch(
    newSearch: SaveAdvancedSearchRequest
  ): Promise<RavenApiStandardResponse> {
    const apiResponse: any = await app.$ravenapi.post(
      "api/advancedsearch/searches/savenewsearch",
      newSearch
    );

    return apiResponse;
  }
}

export const advancedSearchService = Object.freeze(new AdvancedSearchService());
