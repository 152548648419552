



















































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import StandardInput from "@/components/design-system/inputs/StandardInput.vue";
import SimpleConfirmationModal from "@/components/design-system/modals/SimpleConfirmationModal.vue";
import TableSkeleton from "@/components/skeletons/TableSkeleton.vue";
import { reportErrorCaptured } from "@/helpers/error-captured";
import { downloadCsvFile } from "@/helpers/csv-file-helper";
import {
  AdvancedSearchItem,
  ResultColumns,
  SaveAdvancedSearchRequest
} from "@/interfaces/advanced-search/advanced-search-interfaces";
import { ModalTableHeader } from "@/models/document-entry/modal-tables";
import { advancedSearchService } from "@/services/advanced-searches.service";
import Catch from "@/shared/decorators/catch-errors";
import options from "@/shared/constants/toast-options";
import AdvancedSearchStore from "@/store/advanced-search.store";

@Component({
  components: {
    "simple-confirmation-modal": SimpleConfirmationModal,
    "standard-input": StandardInput,
    "table-skeleton": TableSkeleton
  }
})
export default class AdvancedSearchResults extends Vue {
  // Props
  @Prop({ default: () => [] }) headers!: ResultColumns[];
  @Prop({ default: false }) fetchingData!: boolean;

  // Reactive Properties
  private resetSaveModal = Symbol();
  private resetDownloadModal = Symbol();
  private advancedSearchResultColumns: ModalTableHeader[] = [];
  private isSaveModalVisible = false;
  private isSearchSavingInProgress = false;
  private searchName = "";
  private isDownloadModalVisible = false;
  private fileToBeDownloadedName = "";
  private csvHeaders: string[] = []; // for csv file download
  private headerToEntryLinker: string[] = []; // for csv file download

  // Store

  private readonly advancedSearchStore: AdvancedSearchStore = getModule(
    AdvancedSearchStore,
    this.$store
  );

  // Computed properties

  private get isSaveSearchConfirmButtonDeactivated(): boolean {
    return this.searchName.length == 0 || this.isSearchSavingInProgress;
  }

  private get isModalDownloadButtonDisabled(): boolean {
    const emptyItems = this.advancedSearchStore.getSearchResults.length <= 0;
    const emptyFileName = this.fileToBeDownloadedName.length <= 0;
    return emptyItems || emptyFileName;
  }

  private get items(): AdvancedSearchItem[] {
    return this.advancedSearchStore.getSearchResults;
  }

  // methods

  handleSaveSearchModal() {
    this.searchName = "";
    this.isSaveModalVisible = !this.isSaveModalVisible;
    this.resetSaveModal = Symbol();
  }

  @Catch(reportErrorCaptured)
  async saveSearch() {
    this.isSearchSavingInProgress = true;
    const saveSearchRequest: SaveAdvancedSearchRequest = {
      public: true,
      pageSize: "150",
      type: "invoice",
      searchName: this.searchName,
      filters: this.advancedSearchStore.getAppliedValuesToSearch,
      values: this.advancedSearchStore.getAppliedFieldsValuesConfig,
      results: this.advancedSearchStore.getResultsConfig
    };
    const response = await advancedSearchService.saveNewSearch(
      saveSearchRequest
    );
    if (response?.data?.statusCode == 200) {
      this.$toasted.show(
        "<p>Search successfully saved.</p>",
        options.SUCCESS_OPTIONS
      );
    } else {
      this.$toasted.show(
        "<p>" + response?.data?.errorMessage ??
          "Unable to save current search." + "</p>",
        options.ERROR_OPTIONS
      );
    }
    this.handleSaveSearchModal();
    this.isSearchSavingInProgress = false;
  }

  handleDownloadResultsModal() {
    this.fileToBeDownloadedName = "";
    this.isDownloadModalVisible = !this.isDownloadModalVisible;
    this.resetDownloadModal = Symbol();
  }

  downloadResults() {
    downloadCsvFile(
      this.fileToBeDownloadedName,
      this.csvHeaders,
      this.headerToEntryLinker,
      this.items
    );
    this.handleDownloadResultsModal();
  }

  // watchers

  @Watch("headers", { deep: true })
  generateTableColumns() {
    this.csvHeaders = [];
    this.headerToEntryLinker = [];

    if (this.headers?.length > 0) {
      this.advancedSearchResultColumns = [
        {
          text: "Action",
          align: "start",
          sortable: false,
          value: "action"
        },
        ...this.headers?.map(el => {
          this.csvHeaders.push(el.name);
          this.headerToEntryLinker.push(el.idx);
          return {
            text: el?.name,
            align: el?.align ?? "start",
            sortable: true,
            value: el?.idx
          };
        })
      ];
    } else {
      this.advancedSearchResultColumns = [
        {
          text: "Action",
          align: "start",
          sortable: false,
          value: "action"
        }
      ];
    }
  }
}
