

















































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import InputLabel from "@/components/forms/InputLabel.vue";
import IconButton from "@/components/design-system/buttons/IconButton.vue";
import SelectInput from "@/components/forms/document-entry/invoice-lines/SelectInput.vue";
import { GeneratedField } from "@/models/advanced-search/invoice-search-options";

@Component({
  components: {
    IconButton,
    SelectInput,
    "input-label": InputLabel
  }
})
export default class NumericInput extends Vue implements GeneratedField {
  @Prop() label!: string;
  @Prop() placeholder!: string;
  @Prop() name!: string;
  @Prop() value!: string | number;
  @Prop() compValue!: string;
  @Prop() isVisible!: boolean;
  @Prop() maxFloatPrecision!: number;
  @Prop() idx!: string;
  @Prop() removeFilterField!: Function;
  @Prop({ default: "integer" }) valueType?: string;

  private currentValue: string | number = this.value || "";
  private maxFloatPrecisionDigits: number = this.maxFloatPrecision || 2;
  private currentCompValue: string = this.compValue || "=";
  private compValues = [
    { label: "equals", symbol: "=" },
    { label: "greater than", symbol: ">" },
    { label: "greater than or equal to", symbol: ">=" },
    { label: "less than", symbol: "<" },
    { label: "less than or equal to", symbol: "<=" }
  ];

  mounted() {
    if (this.currentCompValue == "") {
      this.currentCompValue = "=";
    }
  }

  handleValueChange(value: string | number) {
    if (this.currentValue == "") {
      const ref = "input-" + this.idx;
      (this.$refs[ref] as any).reset();
    }
    this.$emit("input", {
      currentValue: this.currentValue,
      currentCompValue: this.currentCompValue
    });
  }

  private validateNumericInput(event) {
    if (this.valueType == "integer") {
      if (
        event.code != "Backspace" &&
        event.code != "Delete" &&
        event.code != "ArrowLeft" &&
        event.code != "ArrowRight" &&
        event.code != "Tab" &&
        isNaN(event.key)
      ) {
        event.preventDefault();
        event.stopImmediatePropagation();
      }
    } else if (this.valueType == "float") {
      if (
        (event.code != "Backspace" &&
          event.code != "Delete" &&
          event.code != "ArrowLeft" &&
          event.code != "ArrowRight" &&
          event.code != "Tab" &&
          event.key != "." &&
          isNaN(event.key)) ||
        (event.key == "." &&
          this.value &&
          typeof this.value == "string" &&
          this.value.includes(".")) ||
        (event.code != "Backspace" &&
          event.code != "Tab" &&
          this.value &&
          this.valueType &&
          typeof this.value == "string" &&
          this.value.includes(".") &&
          this.value.toString().split(".")[1].length ==
            this.maxFloatPrecisionDigits)
      ) {
        event.preventDefault();
        event.stopImmediatePropagation();
      }
    }
  }

  compValueChange() {
    this.$emit("input", {
      currentValue: this.currentValue,
      currentCompValue: this.currentCompValue || "="
    });
  }

  removeField() {
    this.removeFilterField(this.idx);
  }

  public clearValue(): void {
    this.currentValue = "";
    this.currentCompValue = "";
    this.$emit("input", {
      currentValue: this.currentValue,
      currentCompValue: this.currentCompValue
    });
  }

  @Watch("value")
  onValueChange() {
    this.currentValue = this.value;
  }
}
