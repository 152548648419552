var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.fetchingData)?_c('div',[_c('table-skeleton')],1):_c('v-data-table',{staticClass:"d-flex flex-column justify-space-between",attrs:{"flat":"","headers":_vm.advancedSearchResultColumns,"items":_vm.items,"items-per-page":15,"footer-props":{
      disablePagination: false,
      showCurrentPage: true,
      rowsPerPage: 15
    },"calculate-widths":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex justify-space-between mb-4 mt-2 px-4"},[_c('h1',{staticClass:"pb-2 karla",attrs:{"id":"result-title"}},[_vm._v("Results")]),_c('div',{staticClass:"d-flex "},[_c('div',{staticClass:"d-flex align-center header-actions",on:{"click":_vm.handleSaveSearchModal}},[_c('v-icon',[_vm._v("mdi-content-save")]),_c('p',{staticClass:"mb-0 ml-1"},[_vm._v("Save Search")])],1),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center header-actions ml-4",on:{"click":_vm.handleDownloadResultsModal}},[_c('v-icon',[_vm._v("mdi-download")]),_c('p',{staticClass:"mb-0 ml-1"},[_vm._v("Download Results")])],1)],1)])]},proxy:true},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","rounded":"lg"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',{staticClass:"table-action"},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',{staticClass:"action-list"},[_c('v-list-item',[_c('v-list-item-title',{},[_c('router-link',{staticClass:"view-doc d-flex align-center",attrs:{"data-eapat-anchor":'AscendId-' + item.invID,"to":'/document/' + item.invID,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-eye")]),_c('p',{staticClass:"karla action-list-item-text mb-0 ml-1"},[_vm._v(" View Document ")])],1)],1)],1)],1)],1)]}}],null,true)}),_c('div',{key:_vm.resetSaveModal},[_c('simple-confirmation-modal',{attrs:{"modalTitle":"Save Search","modalSubtitle":"Enter a name for this search.","confirmButtonText":"Save","isModalDisplayed":_vm.isSaveModalVisible,"handleCloseModal":_vm.handleSaveSearchModal,"handleConfirmSelection":_vm.saveSearch,"isConfirmButtonDisabled":_vm.isSaveSearchConfirmButtonDeactivated,"modalType":"primary"}},[_c('template',{slot:"custom"},[_c('standard-input',{attrs:{"labelText":"Search Name","inputValue":_vm.searchName},on:{"lookup":function($event){_vm.searchName = $event}}})],1)],2)],1),_c('div',{key:_vm.resetDownloadModal},[_c('simple-confirmation-modal',{attrs:{"modalTitle":"Download Results","modalSubtitle":"Enter a name for the file to be downloaded.","confirmButtonText":"Download","isModalDisplayed":_vm.isDownloadModalVisible,"handleCloseModal":_vm.handleDownloadResultsModal,"handleConfirmSelection":_vm.downloadResults,"isConfirmButtonDisabled":_vm.isModalDownloadButtonDisabled,"modalType":"primary"}},[_c('template',{slot:"custom"},[_c('standard-input',{attrs:{"labelText":"File Name","inputValue":_vm.fileToBeDownloadedName},on:{"lookup":function($event){_vm.fileToBeDownloadedName = $event}}})],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }