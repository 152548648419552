





















import { Component, Prop, Vue } from "vue-property-decorator";
import { cloneDeep } from "lodash";
import moment from "moment";
import TextInput from "@/components/advanced-search/TextInput.vue";
import NumericInput from "@/components/advanced-search/NumericInput.vue";
import SelectInput from "@/components/advanced-search/SelectInput.vue";
import DateRangeInput from "@/components/advanced-search/DateRangeInput.vue";
import {
  FieldsGenerator,
  GeneratedField
} from "@/models/advanced-search/invoice-search-options";
import {
  ValuesToSearch,
  ResultColumns
} from "@/interfaces/advanced-search/advanced-search-interfaces";

@Component({
  components: {
    "text-input": TextInput,
    "numeric-input": NumericInput,
    "select-input": SelectInput,
    "date-range-input": DateRangeInput
  }
})
export default class FormGenerator extends Vue implements FieldsGenerator {
  // props
  @Prop() isReadOnly!: boolean;
  @Prop() schema!: ResultColumns[];
  @Prop() value!: ValuesToSearch[];
  @Prop() removeFilterField!: Function;

  // reactive class properties

  private formData: ValuesToSearch[] = cloneDeep(this.value) || []; // to avoid mutating props

  // computed properties

  get computedSchema() {
    return this.schema.map(input => ({
      type: this.getInputComponentType(input.type),
      schemaType: input.type,
      numberType: this.getNumberType(input.type),
      dateType: this.getDateType(input.type),
      name: input.name,
      idx: input.idx,
      label: input.name,
      value: {},
      options: input.options,
      isVisible: true,
      isEditable: !this.isReadOnly
    }));
  }

  // methods

  getValue(field: any, type: string) {
    const valueArray = this.formData.filter(v => v.idx === field.idx);
    if (type == "dtm") {
      return [
        this.getLocalValue(valueArray[0]?.val) ?? "",
        this.getLocalValue(valueArray[1]?.val) ?? ""
      ];
    }
    if (type == "dt") {
      return [valueArray[0]?.val ?? "", valueArray[1]?.val ?? ""];
    }
    if (valueArray[0]) return valueArray[0].val ?? "";
    return this.getDefaultValue(type);
  }

  getUTCValue(val: string) {
    if (val == undefined || val == "") {
      return "";
    }
    let ret = moment(val)
      .utc()
      .format("YYYY-MM-DDTHH:mm");

    if (ret.length == 16) {
      ret += ":00Z";
    }
    return ret;
  }

  getLocalValue(val: string) {
    if (val == undefined || val == "") {
      return "";
    }

    const ret = moment(val)
      .local()
      .format("YYYY-MM-DDTHH:mm:ss");

    return ret;
  }

  getCompValue(field: any) {
    const valueObject = this.formData.filter(v => v.idx === field.idx)[0];
    if (valueObject) return valueObject.comp;
    return this.getDefaultCompValue(field.type);
  }

  getDefaultCompValue(type: string) {
    if (type == "text-input") {
      return "~";
    }
    return "=";
  }

  getDefaultValue(type: string) {
    switch (type) {
      case "bin":
        return false;
      case "str":
        return "";
      case "int":
        return 0;
      case "dt":
        return ["", ""];
      case "flt":
        return 0;
      default:
        return "";
    }
  }

  getValueType(field: any) {
    switch (field.schemaType) {
      case "int":
        return field.numberType;
      case "dt":
        return field.dateType;
      case "dtm":
        return field.dateType;
      case "flt":
        return field.numberType;
      default:
        return "";
    }
  }

  public resetValues(): void {
    // Add values needed as per schema configuration
    for (const field of this.schema) {
      const dataValue = this.formData.find(c => c.idx == field.idx);
      if (!dataValue) {
        const inputValueType =
          this.schema.find(obj => obj.idx == field.idx)?.type ?? "str";
        this.formData.push(this.getEmptyDataValue(field, inputValueType));
      }
    }

    const valueIdxs = this.formData.map(input => input.idx);
    // Remove values not needed from formData
    for (const idx of valueIdxs) {
      const schemaValue = this.schema.find(c => c.idx == idx);
      if (!schemaValue) {
        const valueToRemove = this.formData.find(c => c.idx == idx);
        if (valueToRemove) {
          const ix = this.formData.indexOf(valueToRemove);
          if (ix > -1) {
            this.formData.splice(ix, 1);
          }
        }
      }
    }
  }

  getInputComponentType(type: string | undefined) {
    switch (type) {
      case "bin":
        return "select-input";
      case "str":
        return "text-input";
      case "int":
        return "numeric-input";
      case "dt":
        return "date-range-input";
      case "dtm":
        return "date-range-input";
      case "flt":
        return "numeric-input";
      default:
        return "text-input";
    }
  }

  getEmptyDataValue(field: any, type: string) {
    return {
      cls: field.cls,
      idx: field.idx,
      name: "",
      comp: type == "str" ? "~" : "=",
      val: ""
    };
  }

  getNumberType(type: string | undefined) {
    if (type == "flt") {
      return "float";
    }
    return "integer";
  }

  getDateType(type: string | undefined) {
    if (type == "dtm") {
      return "datetime";
    }
    return "date";
  }

  updateForm(fieldName: string, value: any) {
    this.resetValues();
    let newValue: any = value["currentValue"];
    const valueObjects = this.formData.filter(v => v.idx === fieldName);
    if (valueObjects) {
      const valueObject = valueObjects[0];
      if (valueObject) {
        const el = this.schema.filter(f => f.idx === fieldName);
        if (el[0].type == "dtm") {
          newValue = [
            `${this.getUTCValue(newValue[0])}`,
            `${this.getUTCValue(newValue[1])}`
          ];
        }
        if (valueObjects.length > 1) {
          const firstIndex = this.formData.findIndex(v => v.idx === fieldName);
          let currentIndex = firstIndex + 1; // Start from the index next to the first occurrence
          while (currentIndex !== -1) {
            const nextIndex = this.formData.findIndex(
              (v, index) => index >= currentIndex && v.idx === fieldName
            );
            if (nextIndex !== -1) {
              this.formData.splice(nextIndex, 1); // Remove the next occurrence
              currentIndex = nextIndex; // Move currentIndex to the removed index
            } else {
              // No more occurrences found, exit the loop
              break;
            }
          }
        }
        valueObject.val = newValue;
        valueObject.comp = "";
        valueObject.name = el[0]?.name ?? "";
        const compValue = value["currentCompValue"];
        if (compValue && typeof value["currentCompValue"] == "string") {
          valueObject.comp = value["currentCompValue"];
        }
      }
    }
    this.$emit("updateFormValue", this.formData);
  }

  isGeneratedField(object: any): object is GeneratedField {
    return object;
  }

  public clearAllData(): void {
    if (this.$refs["generated-input"]) {
      const generatedInputs: any = this.$refs["generated-input"];
      if (Array.isArray(generatedInputs)) {
        for (const generatedInput of generatedInputs) {
          if (this.isGeneratedField(generatedInput)) {
            generatedInput.clearValue();
          }
        }
      } else {
        if (this.isGeneratedField(generatedInputs)) {
          generatedInputs.clearValue();
        }
      }
    }
  }
}
