















































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { cloneDeep } from "lodash";
import IconButton from "@/components/design-system/buttons/IconButton.vue";
import { ResultColumns } from "@/interfaces/advanced-search/advanced-search-interfaces";
import {
  CategoryAndFieldsObject,
  SelectedDisplayOption
} from "@/models/advanced-search/invoice-search-options";

@Component({
  components: {
    IconButton
  }
})
export default class ActionableFieldLabelGenerator extends Vue {
  // props

  @Prop() isReadOnly!: boolean;
  @Prop() value!: any;
  @Prop() focusElementRefName!: string;
  @Prop() actionOnItem!: Function;
  @Prop() actionIcon!: string;
  @Prop() groupedBySubCategory!: boolean;
  @Prop({ default: "action" }) actionButtonTooltipText?: string;
  @Prop() selectedFieldsIdxs!: string[];
  @Prop({ default: SelectedDisplayOption.removeSelected })
  selectedDisplayOption?: number;
  @Prop() allAvailableFields!: CategoryAndFieldsObject[];
  @Prop({ default: " " }) searchFieldQuery?: string;

  // reactive class properties

  private allCategoriesAndFields: CategoryAndFieldsObject[] = [];
  private allFields: ResultColumns[] = [];
  private categoriesAndFields = [];
  private fields: ResultColumns[] = [];
  private expandCategoriesSections = 0;

  // computed properties

  get getActionIconName() {
    return this.actionIcon;
  }

  get getCategoriesAndFields() {
    return this.categoriesAndFields;
  }

  get getFields() {
    return this.fields;
  }

  // lifecycle methods

  mounted() {
    if (this.allAvailableFields) {
      this.initializeFormGenerator();
    }
  }

  // methods

  initializeFormGenerator() {
    this.categoriesAndFields = JSON.parse(
      JSON.stringify(this.allAvailableFields)
    );

    this.allCategoriesAndFields = JSON.parse(
      JSON.stringify(this.allAvailableFields)
    );
    this.filterCategoriesAndFieldsBySelectedFields();

    this.allFields = [...this.allCategoriesAndFields.flatMap(cf => cf.fields)];

    this.allFields = this.sortFields(this.allFields, "name");

    this.filterFieldsBySelectedIdxs();
  }

  private filterBySearchQueryCategoriesAndFieldsBySelectedFields() {
    const listOfSelectedIdxs = this.selectedFieldsIdxs
      .map(idx => `-${idx.toLowerCase()}-`)
      .join(" ");

    const newCategoriesAndFields: any[] = [];

    this.allCategoriesAndFields = this.sortFields(
      this.allAvailableFields,
      "category"
    );

    const filterQuery = this.searchFieldQuery?.toLowerCase()?.trim() ?? "";

    for (const category of this.allCategoriesAndFields) {
      const categoryFields: any[] = category["fields"];
      const fields: any[] = [];
      for (const field of categoryFields) {
        const strIdx = field["idx"].toLowerCase();

        const isAlreadySelected =
          listOfSelectedIdxs.indexOf(`-${strIdx}-`) != -1;

        const isQueryMatch =
          field["name"].toLowerCase().indexOf(filterQuery) > -1;

        if (isAlreadySelected) continue;

        if (!isAlreadySelected && isQueryMatch) {
          fields.push(field);
        }
      }

      if (fields.length > 0) {
        const categoryItem: any = JSON.parse(JSON.stringify(category));
        categoryItem["fields"] = this.sortFields(fields, "name");
        newCategoriesAndFields.push(categoryItem);
      }
    }
    this.categoriesAndFields = JSON.parse(
      JSON.stringify(newCategoriesAndFields)
    );
  }

  private filterCategoriesAndFieldsBySelectedFields() {
    const listOfSelectedIdxs = this.selectedFieldsIdxs
      .map(idx => `-${idx.toLowerCase()}-`)
      .join(" ");

    const newCategoriesAndFields: any[] = [];

    this.allCategoriesAndFields = this.sortFields(
      this.allAvailableFields,
      "category"
    );

    for (const category of this.allCategoriesAndFields) {
      const categoryFields: any[] = category["fields"];
      const fields: any[] = [];
      for (const field of categoryFields) {
        const strIdx = field["idx"].toLowerCase();

        const isAlreadySelected =
          listOfSelectedIdxs.indexOf(`-${strIdx}-`) != -1;

        if (isAlreadySelected) continue;

        if (!isAlreadySelected) {
          fields.push(field);
        }
      }

      if (fields.length > 0) {
        const categoryItem: any = JSON.parse(JSON.stringify(category));
        categoryItem["fields"] = this.sortFields(fields, "name");
        newCategoriesAndFields.push(categoryItem);
      }
    }
    this.categoriesAndFields = JSON.parse(
      JSON.stringify(newCategoriesAndFields)
    );
  }

  private filterFieldsBySelectedIdxs() {
    const listOfSelectedIdxs = this.selectedFieldsIdxs
      .map(idx => `-${idx.toLowerCase()}-`)
      .join(" ");

    const newFields: any[] = [];

    this.allFields = this.sortFields(this.allFields, "name");

    for (const field of this.allFields) {
      const strIdx = field.idx.toLowerCase();

      if (this.selectedDisplayOption == SelectedDisplayOption.removeSelected) {
        if (listOfSelectedIdxs.indexOf(`-${strIdx}-`) == -1) {
          newFields.push(field);
        }
      }

      if (
        this.selectedDisplayOption == SelectedDisplayOption.showOnlySelected
      ) {
        if (
          listOfSelectedIdxs != "" &&
          listOfSelectedIdxs.indexOf(`-${strIdx}-`) >= 0
        ) {
          newFields.push(field);
        }
      }

      this.fields = JSON.parse(JSON.stringify(newFields));
    }
  }

  private sortFields(filters, field) {
    const copiedFilters = cloneDeep(filters);
    copiedFilters.sort(function(a, b) {
      const x = a[field].toLowerCase();
      const y = b[field].toLowerCase();
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
    return copiedFilters;
  }

  // watchers

  @Watch("searchFieldQuery")
  onSearchFieldQueryChange() {
    this.expandCategoriesSections = 1;

    if (this.searchFieldQuery && this.searchFieldQuery.length > 0) {
      this.expandCategoriesSections = 0;
    }
    if (this.searchFieldQuery != "") {
      this.filterBySearchQueryCategoriesAndFieldsBySelectedFields();
    } else {
      this.filterCategoriesAndFieldsBySelectedFields();
    }
  }

  @Watch("selectedFieldsIdxs")
  onFieldIndexesToOmitChange() {
    if (this.searchFieldQuery != "") {
      this.filterBySearchQueryCategoriesAndFieldsBySelectedFields();
    } else {
      this.filterCategoriesAndFieldsBySelectedFields();
    }

    this.filterFieldsBySelectedIdxs();
  }

  @Watch("allAvailableFields", { deep: true })
  onAllAvailableFieldsLoaded() {
    this.initializeFormGenerator();
  }
}
