














































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import InputLabel from "@/components/forms/InputLabel.vue";
import IconButton from "@/components/design-system/buttons/IconButton.vue";
import { GeneratedField } from "@/models/advanced-search/invoice-search-options";

@Component({
  components: {
    IconButton,
    "input-label": InputLabel
  }
})
export default class SelectInput extends Vue implements GeneratedField {
  @Prop() label!: string;
  @Prop() name!: string;
  @Prop() value!: number | string | boolean;
  @Prop() compValue!: string;
  @Prop() options!: any[];
  @Prop() isVisible!: boolean;
  @Prop() idx!: string;
  @Prop() removeFilterField!: Function;

  // below is a way to auto-select correct option in select list
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  private currentValue: { text: string; value: boolean } = this.options?.find(
    (option: { text: string; value: any }) => option.value == this.value
  ) || { text: "", value: "" };

  private currentCompValue: string = this.compValue || "=";
  private compValues = [{ label: "equals", symbol: "=" }];

  mounted() {
    this.currentValue = this.options.find(
      (option: { text: string; value: any }) => option.value === this.value
    );
    if (this.currentValue) {
      this.textValueOrComparerValueChanged();
    }
  }

  textValueOrComparerValueChanged() {
    this.$emit("input", {
      currentValue: this.currentValue.value,
      currentCompValue: this.currentCompValue
    });
  }

  removeField() {
    this.removeFilterField(this.idx);
  }

  public clearValue(): void {
    this.$emit("input", "");
  }

  @Watch("value")
  onValuePropChange() {
    this.currentValue = this.options.find(
      (option: { text: string; value: any }) => option.value == this.value
    );
  }
}
