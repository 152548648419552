




































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import InputLabel from "@/components/forms/InputLabel.vue";
import { focusField } from "@/helpers/input-helpers";

@Component({
  components: {
    "input-label": InputLabel
  }
})
export default class TextInput extends Vue {
  @Prop() lineSequenceNumber!: number;
  @Prop() focusElementRefName!: string;
  @Prop() isReadOnly!: boolean;
  @Prop() label!: string;
  @Prop() name!: string;
  @Prop() value!: number | string | boolean;
  @Prop() options!: any[];
  @Prop() isRequired!: boolean;
  @Prop() isValid!: boolean;
  @Prop() isError!: boolean;
  @Prop() errorMessage?: string;
  @Prop() isVisible!: boolean;
  @Prop() isEditable!: boolean;
  // below is a way to auto-select correct option in select list
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  private currentValue: { text: string; value: boolean } = this.options!.find(
    (option: { text: string; value: boolean }) => option.value === this.value!
  );

  handleTabKeypress(event: any) {
    if (event.shiftKey) {
      this.$emit("tabToPreviousField");
    } else {
      this.$emit("tabToNextField");
    }
  }

  @Watch("value")
  onValuePropChange() {
    this.currentValue = this.options!.find(
      (option: { text: string; value: boolean }) => option.value === this.value!
    );
  }

  @Watch("focusElementRefName")
  onFocusElementChange() {
    focusField(this);
  }

  mounted() {
    focusField(this);
  }
}
