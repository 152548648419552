
















































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import InputLabel from "@/components/forms/InputLabel.vue";
import SelectInput from "@/components/forms/document-entry/invoice-lines/SelectInput.vue";
import IconButton from "@/components/design-system/buttons/IconButton.vue";
import { GeneratedField } from "@/models/advanced-search/invoice-search-options";

@Component({
  components: {
    IconButton,
    SelectInput,
    "input-label": InputLabel
  }
})
export default class TextInput extends Vue implements GeneratedField {
  @Prop() label!: string;
  @Prop() placeholder!: string;
  @Prop() name!: string;
  @Prop() value!: string;
  @Prop() compValue!: string;
  @Prop() isVisible!: boolean;
  @Prop() idx!: string;
  @Prop() removeFilterField!: Function;

  private currentValue: string = this.value || "";
  private currentCompValue: string = this.compValue || "";
  private compValues = [
    { label: "contains", symbol: "~" },
    { label: "equals", symbol: "=" },
    { label: "not equal to", symbol: "<>" },
    { label: "less than", symbol: "<" },
    { label: "greater than", symbol: ">" }
  ];
  private maxLength = 60;

  mounted() {
    if (this.currentCompValue == "" || !this.currentCompValue) {
      this.currentCompValue = "~";
    }
  }

  textValueOrComparerValueChanged() {
    if (this.currentValue == "") {
      const ref = "input-" + this.idx;
      (this.$refs[ref] as any).reset();
    }
    this.$emit("input", {
      currentValue: this.currentValue,
      currentCompValue: this.currentCompValue
    });
  }

  textCount(event) {
    if (
      this.currentValue?.length + 1 > this.maxLength &&
      event.code != "Backspace"
    ) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  removeField() {
    this.removeFilterField(this.idx);
  }

  public clearValue(): void {
    this.currentValue = "";
    this.currentCompValue = "";
    this.$emit("input", {
      currentValue: this.currentValue,
      currentCompValue: this.currentCompValue
    });
  }

  @Watch("value")
  onValuePropChange() {
    this.currentValue = this.value;
  }
}
