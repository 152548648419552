var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input-container",attrs:{"hidden":!_vm.isVisible}},[_c('div',{staticClass:"d-flex flex-column pa-0 ma-0"},[_c('div',{staticClass:"d-flex field-label-container"},[_c('div',{staticClass:"action-label-section"},[_c('div',{staticClass:"delete-action-section"},[_c('IconButton',{attrs:{"tooltipText":"Remove from filters","materialIconToDisplay":"mdi-minus-circle","tooltipColor":"primary","handle-click":function () { return _vm.removeField(); }}})],1),_c('div',{staticClass:"label-section input-label"},[_c('p',{staticClass:"label-text"},[_vm._v(_vm._s(_vm.label))])])]),_c('div',{staticClass:"compare-operator-section"})]),_c('div',{staticClass:"d-flex pa-0 ma-0 justify-lg-space-between from-text-input-container",class:_vm.getDateType() == 'date'
          ? 'date-fields-container'
          : 'date-fields-container-local'},[_c('v-text-field',{staticClass:"from-text-input",attrs:{"clearable":"","type":_vm.getDateType(),"min":'1753-01-01',"max":_vm.currentValueTo},on:{"change":_vm.changeFromInput,"click:clear":function () {
            this$1.fromValueCleared = true;
          }},model:{value:(_vm.currentValueFrom),callback:function ($$v) {_vm.currentValueFrom=$$v},expression:"currentValueFrom"}}),_c('v-text-field',{staticClass:"to-text-input",attrs:{"clearable":"","type":_vm.getDateType(),"min":_vm.currentValueFrom || '1753-01-01',"max":'9999-12-31'},on:{"change":_vm.changeToInput,"click:clear":function () {
            this$1.toValueCleared = true;
          }},model:{value:(_vm.currentValueTo),callback:function ($$v) {_vm.currentValueTo=$$v},expression:"currentValueTo"}})],1),_c('div',{staticClass:"blank-row"})])])}
var staticRenderFns = []

export { render, staticRenderFns }