






















































































































































































































import { Vue, Component, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { cloneDeep } from "lodash";
import FormGenerator from "@/components/advanced-search/FormGenerator.vue";
import AdvancedSearchResults from "@/components/advanced-search/AdvancedSearchResults.vue";
import InputLabel from "@/components/forms/InputLabel.vue";
import ActionableFieldLabelGenerator from "@/components/advanced-search/ActionableFieldValueGenerator.vue";
import SecondaryButton from "@/components/design-system/buttons/SecondaryButton.vue";
import PrimaryButton from "@/components/design-system/buttons/PrimaryButton.vue";
import { checkCurrentRouteAndRedirect } from "@/helpers/router-helpers";
import {
  AdvancedSearchItem,
  ResultColumns,
  ValuesToSearch
} from "@/interfaces/advanced-search/advanced-search-interfaces";
import { RavenApiStandardResponse } from "@/models/external-services/raven-api-standard-response";
import {
  CategoryAndFieldsObject,
  FieldsGenerator,
  SelectedDisplayOption
} from "@/models/advanced-search/invoice-search-options";
import options from "@/shared/constants/toast-options";
import { advancedSearchService } from "@/services/advanced-searches.service";
import AdvancedSearchStore from "@/store/advanced-search.store";
import { randomNumberGenerator } from "@/helpers/numbers-helpers";
import { utils } from "@/utils/okta-utils";
import Decimal from "decimal.js";
import moment from "moment";

@Component({
  components: {
    InputLabel,
    "form-generator": FormGenerator,
    "actionable-field-label-generator": ActionableFieldLabelGenerator,
    "advanced-search-results": AdvancedSearchResults,
    "clear-all": SecondaryButton,
    update: PrimaryButton
  }
})
export default class DocumentSearch extends Vue {
  // reactive class properties

  private isComponentInitializing = false;
  private formGeneratorKey = randomNumberGenerator();
  private filtersOptionsFormKey = randomNumberGenerator();
  private resultsFormGeneratorKey = randomNumberGenerator();
  private resultsOptionsFormGeneratorKey = randomNumberGenerator();
  private currentlySelectedTab = null; // used to control currently selected tab
  private savedSearchDetails: any | null = null;
  private isReadonly = false;
  private searchFieldQuery = "";
  private resultsSearchFieldQuery = "";
  private allCategoryAndFields: CategoryAndFieldsObject[] | null = null;
  private allFields: ResultColumns[] = [];
  private resultColumns: ResultColumns[] = [];
  private isUpdateButtonDisabled = true;
  private fetchingData = false;
  private toggleSearchAddFieldsPanel = 1;
  private toggleSearchResultAddFieldsPanel = 1;
  private readonly amountFields = [
    "ControlAmt",
    "controlAmt",
    "FreightAmt",
    "freightAmt",
    "OtherCharges",
    "otherCharges",
    "TaxAmt",
    "taxAmt",
    "DistUnitCost",
    "distUnitCost",
    "DistExtendedAmt",
    "distExtendedAmt"
  ];
  private readonly dateTimeFields = [
    "invScannedDate",
    "invCancelledDate",
    "invDeletedDate",
    "invEnteredDate",
    "invOwnerTimestamp"
  ];

  // readonly store modules

  private readonly advancedSearchStore: AdvancedSearchStore = getModule(
    AdvancedSearchStore,
    this.$store
  );

  // computed properties

  private get savedSearchID(): string | number {
    return this.advancedSearchStore.getSearchID;
  }
  private set savedSearchID(id: string | number) {
    this.advancedSearchStore.setSearchID(id);
  }
  private get resultsConfig(): any {
    return this.advancedSearchStore.getResultsConfig || [];
  }
  private set resultsConfig(config: any) {
    this.advancedSearchStore.setResultsConfig(config);
  }
  private get filtersConfig(): any {
    return this.advancedSearchStore.getAppliedValuesToSearch || [];
  }
  private set filtersConfig(config: any) {
    this.advancedSearchStore.setAppliedValuesToSearch(config);
  }
  private get valuesConfig(): any {
    return this.advancedSearchStore.getAppliedFieldsValuesConfig || [];
  }
  private set valuesConfig(config: any) {
    this.advancedSearchStore.setAppliedFieldsValuesConfig(config);
  }
  private get selectedResultsFieldIds(): string[] {
    return (this.resultsConfig?.map(result => result?.idx) ?? []) || [];
  }
  private set selectedResultsFieldIds(selectedResults: string[]) {
    this.selectedResultsFieldIds = selectedResults;
  }
  private get selectedFieldsIds(): string[] {
    return (this.filtersConfig?.map(filter => filter?.idx) ?? []) || [];
  }
  private set selectedFieldsIds(selectedFields) {
    this.selectedFieldsIds = selectedFields;
  }
  get listOnlySelectedItems() {
    return SelectedDisplayOption.showOnlySelected;
  }
  get listAllButSelectedItems() {
    return SelectedDisplayOption.removeSelected;
  }

  // lifecycle methods

  async created() {
    this.isComponentInitializing = true;
    this.advancedSearchStore.clearStateFromPastSearch();
    this.resultColumns = [];
    this.searchFieldQuery = " "; // value causes the Add Fields section to be expanded
    this.resultsSearchFieldQuery = " "; // value causes the Add Fields section to be expanded

    // load all options for filters/results from API
    const advancedSearchCategories: CategoryAndFieldsObject[] = await advancedSearchService.getAdvancedSearchOptions();
    if (advancedSearchCategories && advancedSearchCategories?.length > 0) {
      this.initAllFieldsArray(advancedSearchCategories);
    }

    if (this.$route.params.id) {
      await this.loadExistingSavedSearch();
    }
    this.isComponentInitializing = false;
  }

  // methods

  initAllFieldsArray(availableCategoriesList: CategoryAndFieldsObject[]) {
    this.allCategoryAndFields = availableCategoriesList;
    for (const availableFieldsJsonElement of availableCategoriesList) {
      this.allFields = [
        ...this.allFields,
        ...availableFieldsJsonElement["fields"]
      ];
    }
  }

  async loadExistingSavedSearch() {
    const savedSearchDetails = await advancedSearchService.loadExistingSearchAsync(
      this.$route.params.id
    );
    if (
      (savedSearchDetails as RavenApiStandardResponse).data?.errorMessage &&
      (savedSearchDetails as RavenApiStandardResponse).data?.errorMessage ===
        "No saved search was found"
    ) {
      // saved search not found, remove searchID from URL and notify user
      this.$toasted.show(
        "No matching search found, please create a new search",
        options.INFO_OPTIONS
      );
      checkCurrentRouteAndRedirect(this.$router, "/document-search");
    } else {
      // update store
      this.updateStoreWithInitialState(savedSearchDetails);
    }
  }

  updateStoreWithInitialState(savedSearchDetails: any) {
    this.savedSearchDetails = savedSearchDetails;
    // load filters and results columns with selected values
    const resultsConfig = JSON.parse(savedSearchDetails.ssResults);
    resultsConfig.forEach(result => {
      this.addResultField(result.idx);
    });
    const filtersConfig = JSON.parse(savedSearchDetails.ssFilters);
    filtersConfig.forEach(filter => {
      this.addFilterField(filter.idx);
    });
    this.valuesConfig = JSON.parse(savedSearchDetails.ssValues);
    // store saved search ID and reload the form generator
    this.savedSearchID = savedSearchDetails.ssID;
    // refresh forms
    this.refreshFormComponents();
  }

  refreshFormComponents() {
    this.formGeneratorKey = randomNumberGenerator();
    this.filtersOptionsFormKey = randomNumberGenerator();
    this.resultsFormGeneratorKey = randomNumberGenerator();
    this.resultsOptionsFormGeneratorKey = randomNumberGenerator();
  }

  addFilterField(idx: string) {
    const newFilter = this.allFields.find(c => c.idx == idx);
    if (newFilter) {
      if (!this.filtersConfig.find(c => c.idx == idx)) {
        this.filtersConfig.push(newFilter);
      }
    }
  }

  addResultField(idx: string) {
    const newResult = this.allFields.find(c => c.idx == idx);
    if (newResult) {
      this.resultColumns.push({
        align: this.amountFields.includes(newResult.idx) ? "end" : "start", // right justify amounts
        cls: newResult.cls,
        idx: newResult.idx,
        name: newResult.name,
        type: newResult.type,
        sortable: true,
        value: newResult.value,
        options: []
      });
      this.resultsConfig.push(newResult);
    }
  }

  removeFilterField(idx: string) {
    const filterToRemove = this.filtersConfig.find(filter => filter.idx == idx);
    if (filterToRemove) {
      const index = this.filtersConfig.indexOf(filterToRemove);
      if (index > -1) {
        this.filtersConfig.splice(index, 1);
        this.valuesConfig = this.valuesConfig.filter(value => value.idx != idx);
      }
    }
    if (this.$refs.fieldGenerator) {
      const generator: any = this.$refs.fieldGenerator;
      if (this.isFieldGenerator(generator)) {
        generator.resetValues();
      }
    }
  }

  removeResultField(idx: string) {
    this.resultColumns = this.resultColumns.filter(
      column => column.idx !== idx
    );
    this.resultsConfig = this.resultsConfig.filter(
      result => result.idx !== idx
    );
  }

  isFieldGenerator(object: any): object is FieldsGenerator {
    return object;
  }

  clearValuesData() {
    this.filtersConfig.splice(0, this.filtersConfig.length);
    this.valuesConfig.splice(0, this.valuesConfig.length);
    if (this.$refs.fieldGenerator) {
      const generator: any = this.$refs.fieldGenerator;
      if (this.isFieldGenerator(generator)) {
        generator.resetValues();
      }
    }
  }

  clearResultsValuesData() {
    this.resultsConfig = [];
    this.resultColumns = [];
  }

  async requestResults() {
    this.fetchingData = true;
    this.isUpdateButtonDisabled = true;
    const advancedSearchResultItems = await advancedSearchService.getAdvancedSearchResults(
      {
        valuesToSearch: this.valuesConfig,
        resultColumns: this.resultsConfig
      }
    );
    const formatedItems: AdvancedSearchItem[] = cloneDeep(
      advancedSearchResultItems
    );
    formatedItems.forEach(item => {
      for (const key in item) {
        if (this.dateTimeFields.includes(key)) {
          item[key] = utils.utcToLocalDateString(item[key]);
        } else if (
          key.toLowerCase().includes("date") &&
          typeof item[key] == "string" &&
          key.toLowerCase() != "taxpointdatetypetext"
        ) {
          item[key] = moment(item[key]).format("L");
        }
        if (this.amountFields.includes(key)) {
          item[key] = this.formatToDecimalOrZero(item[key]);
        }
      }
    });
    this.advancedSearchStore.setSearchResults(formatedItems);
    this.fetchingData = false;
    this.isUpdateButtonDisabled = false;
  }

  appliedFieldsValuesChanged(newValue: ValuesToSearch[]) {
    const formatedValues: ValuesToSearch[] = [];
    newValue.forEach(el => {
      if (typeof el.val === "string" && (el.val?.length ?? 0) > 0) {
        formatedValues.push(el);
      } else if (typeof el.val !== "string") {
        const isElValAnArray = Array.isArray(el.val);
        if (isElValAnArray && el.val[0] != "" && el.val[1] != "") {
          formatedValues.push(
            this.generateValueConfig(
              this.getDateTimeFormat(el.val[0], "00:00:00"),
              ">=",
              el
            )
          );
          formatedValues.push(
            this.generateValueConfig(
              this.getDateTimeFormat(el.val[1], "23:59:59"),
              "<=",
              el
            )
          );
        } else if (isElValAnArray && el.val[0] != "" && el.val[1] == "") {
          formatedValues.push(
            this.generateValueConfig(
              this.getDateTimeFormat(el.val[0], "00:00:00"),
              ">=",
              el
            )
          );
        } else if (isElValAnArray && el.val[0] == "" && el.val[1] != "") {
          formatedValues.push(
            this.generateValueConfig(
              this.getDateTimeFormat(el.val[1], "23:59:59"),
              "<=",
              el
            )
          );
        }
      }
    });
    this.valuesConfig = formatedValues;
  }

  formatToDecimalOrZero(value: string | null): string {
    if (value === null) {
      value = "0";
    }
    return new Decimal(value).toFixed(2);
  }

  getDateTimeFormat(date: string, time: string): string {
    return date.includes("T") ? date : date + "T" + time;
  }

  private generateValueConfig(
    val: string,
    comp: string,
    el: ValuesToSearch
  ): ValuesToSearch {
    return {
      cls: el.cls,
      idx: el.idx,
      name: el.name,
      comp: comp,
      val: val
    };
  }

  changeDisableButtonStatus() {
    this.isUpdateButtonDisabled =
      this.valuesConfig.length <= 0 || this.resultsConfig.length <= 0;
  }

  // watchers

  @Watch("resultsConfig", { deep: true })
  onResultsConfigChange() {
    this.changeDisableButtonStatus();
    this.advancedSearchStore.setSearchResults([]);
  }

  @Watch("valuesConfig", { deep: true })
  onFiltersConfigChange() {
    this.changeDisableButtonStatus();
    this.advancedSearchStore.setSearchResults([]);
  }

  @Watch("searchFieldQuery")
  onSearchFieldQueryChange() {
    this.toggleSearchAddFieldsPanel = 1;
    if (this.searchFieldQuery && this.searchFieldQuery.length > 0) {
      this.toggleSearchAddFieldsPanel = 0;
    }
  }

  @Watch("resultsSearchFieldQuery")
  onSearchResultFieldQueryChange() {
    this.toggleSearchResultAddFieldsPanel = 1;
    if (
      this.resultsSearchFieldQuery &&
      this.resultsSearchFieldQuery.length > 0
    ) {
      this.toggleSearchResultAddFieldsPanel = 0;
    }
  }
}
