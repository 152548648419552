





























































import { Vue, Component, Prop } from "vue-property-decorator";
import InputLabel from "@/components/forms/InputLabel.vue";
import IconButton from "@/components/design-system/buttons/IconButton.vue";
import { GeneratedField } from "@/models/advanced-search/invoice-search-options";

@Component({
  components: {
    IconButton,
    "input-label": InputLabel
  }
})
export default class DateRangeInput extends Vue implements GeneratedField {
  // props

  @Prop() label!: string;
  @Prop() placeholder!: string;
  @Prop() name!: string;
  @Prop() value!: string[];
  @Prop() compValue!: string;
  @Prop() isVisible!: boolean;
  @Prop() idx!: string;
  @Prop() removeFilterField!: Function;
  @Prop({ default: "date" }) valueType?: string;

  // reactive class properties

  private currentValueFrom = "";
  private currentValueTo = "";
  private fromValueCleared = false;
  private toValueCleared = false;

  // methods

  mounted() {
    if (this.value && Array.isArray(this.value)) {
      if (this.value[0] && Array.isArray(this.value[0])) {
        this.currentValueFrom = this.calculateInputValue(this.value[0][0]);
        this.currentValueTo = this.calculateInputValue(this.value[0][1]);
      } else {
        this.currentValueFrom = this.calculateInputValue(this.value[0]);
        this.currentValueTo = this.calculateInputValue(this.value[1]);
      }
    }
  }

  removeTimeFromDate(value: string) {
    if (value) {
      return value?.trim()?.substring(0, value?.indexOf("T")) ?? "";
    }
    return "";
  }

  calculateInputValue(value: string) {
    if (value) {
      if (value?.includes("T") && this.valueType?.toLowerCase() != "datetime") {
        return this.removeTimeFromDate(value);
      }
      return value?.trim() ?? "";
    }
    return "";
  }

  changeFromInput() {
    this.fromValueCleared = false;
    if (this.value && Array.isArray(this.value)) {
      this.$emit("input", {
        currentValue: [
          this.calculateInputValue(this.currentValueFrom),
          this.calculateInputValue(this.currentValueTo)
        ]
      });
    } else {
      this.$emit("input", {
        currentValue: [this.calculateInputValue(this.currentValueFrom), ""]
      });
    }
  }

  changeToInput() {
    this.toValueCleared = false;
    if (this.value && Array.isArray(this.value)) {
      this.$emit("input", {
        currentValue: [
          this.calculateInputValue(this.currentValueFrom),
          this.calculateInputValue(this.currentValueTo)
        ]
      });
    } else {
      this.$emit("input", {
        currentValue: ["", this.calculateInputValue(this.currentValueTo)]
      });
    }
  }

  getDateType() {
    if (this.valueType?.toLowerCase() == "datetime") {
      return "datetime-local";
    }
    return "date";
  }

  removeField() {
    this.removeFilterField(this.idx);
  }

  public clearValue(): void {
    this.currentValueFrom = "";
    this.currentValueTo = "";
    this.$emit("input", {
      currentValue: ["", ""]
    });
  }
}
